import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "components/button"
import { Dropdown } from "components/Dropdown/Dropdown"
import Input from "components/input"
import CustomSelect from "components/Select/CustomSelect"
import React, { useState } from "react"
import {
  FieldValues,
  FormProvider,
  UseFormHandleSubmit,
  UseFormReset,
  UseFormReturn,
} from "react-hook-form"
import { faAngleDown, faFilter } from "@fortawesome/free-solid-svg-icons"
import { ReactComponent as ReloadIcon } from "assets/icons/reload.svg"
import ReactDatePicker from "react-datepicker"
import { format } from "date-fns"

type Props = {
  filter: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    filterMethods: UseFormReturn<FieldValues, any>
    handleFilter: UseFormHandleSubmit<FieldValues>
    onFilter: (data: FieldValues) => void
    resetFilter: UseFormReset<FieldValues>
  }
  search: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    searchMethods: UseFormReturn<FieldValues, any>
    handleSearch: UseFormHandleSubmit<FieldValues>
    onSearch: (data: FieldValues) => void
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateSearchParams: (payload: any) => void
  }
}

const DisputeFilter: React.FC<Props> = ({ filter, search }) => {
  const [showFilter, setShowFilter] = React.useState(false)
  const { handleSubmit: handleSearch } = search.searchMethods

  const [startDate, setStartDate] = useState<Date | null>()
  const [endDate, setEndDate] = useState<Date | null>()

  const toggleFilter = () => {
    setShowFilter(!showFilter)
  }

  return (
    <>
      <Dropdown
        isOpen={showFilter}
        toggleOpen={toggleFilter}
        showBackdrop={false}
        trigger={
          <Button
            variant="link"
            className="relative flex h-10 min-w-[7.5rem] items-center justify-evenly gap-[15px] rounded-md px-4 text-base font-medium text-primary-default md:min-w-[12.875rem] md:bg-white md:shadow-lg"
          >
            <FontAwesomeIcon className="ml-auto" icon={faFilter} />
            <span className="hidden md:inline-block">Filter by</span>
            <span className="hidden text-gray-10/50 md:inline-block">
              <FontAwesomeIcon icon={faAngleDown} />
            </span>
          </Button>
        }
        content={
          <FormProvider {...filter.filterMethods}>
            <form
              onSubmit={filter.handleFilter((data) => {
                return filter.onFilter({
                  ...data,
                  startDate: startDate && format(startDate, "dd-MM-yyyy"),
                  endDate: endDate && format(endDate, "dd-MM-yyyy"),
                  resolution: data?.resolution?.value,
                })
              })}
              autoComplete="off"
            >
              <div className="flex flex-col gap-3">
                {/* <CustomSelect label="Reason" options={[]} name="reason" /> */}
                <CustomSelect
                  label="Resolution"
                  options={[
                    { value: "", label: "All" },
                    { value: "resolved", label: "Resolved" },
                    { value: "unresolved", label: "Unresolved" },
                  ]}
                  name="resolution"
                />
                <div>
                  <label htmlFor="startDate">Start Date</label>
                  <ReactDatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    startDate={startDate}
                    className="w-full border-gray-20"
                    maxDate={new Date()}
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
                <div>
                  <label htmlFor="startDate">End Date</label>
                  <ReactDatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    startDate={endDate}
                    className="w-full border-gray-20"
                    maxDate={new Date()}
                    minDate={startDate}
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
                <div className="mt-2 flex justify-between gap-6">
                  <Button
                    className="flex items-center gap-2"
                    variant="outlineDefault"
                    onClick={() => {
                      filter.resetFilter()
                      setStartDate(null)
                      setEndDate(null)
                    }}
                  >
                    <ReloadIcon /> Reset
                  </Button>
                  <Button className="w-28">Apply</Button>
                </div>
              </div>
            </form>
          </FormProvider>
        }
      />
      <FormProvider {...search.searchMethods}>
        <form onSubmit={handleSearch(search.onSearch)} autoComplete="off">
          <Input
            name="search"
            type="search"
            inputContainerProps={{ className: "md:w-[15rem]" }}
            placeholder="Search by dispute ref"
          />
        </form>
      </FormProvider>
    </>
  )
}

export default DisputeFilter
